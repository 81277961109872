import * as React from 'react';
import { Fragment, FC } from 'react';
import MuiToolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import IconCancel from '@material-ui/icons/Cancel';
import { SaveButton,
    Button,
    Record,
    ToolbarProps } from 'react-admin';

export interface OrderDetail extends Record {
    Qty_Loaded: number;
    Confirmed: boolean;
    OrderID: Identifier;
    Release_Num: string;
}

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

const OrderDetailEditToolbar: FC<ToolbarProps<OrderDetail>> = ({
    basePath,
    handleSubmitWithRedirect,
    invalid,
    record,
    resource,
    saving,
    onCancel,
}) => {
    const classes = useStyles();

    if (!record) return null;
    return (
        <MuiToolbar className={classes.root}>
                <Fragment>
                    <SaveButton
                        handleSubmitWithRedirect={handleSubmitWithRedirect}
                        invalid={invalid}
                        saving={saving}
                        redirect="list"
                        submitOnEnter={true}
                    />
                    <Button label="ra.action.cancel" onClick={onCancel} >
                        <IconCancel />
                    </Button>
                </Fragment>
        </MuiToolbar>
    );
};

export default OrderDetailEditToolbar;
