import React from 'react';
import {
    Datagrid,
    TextField,
    DateField,
    Show,
    SimpleShowLayout,
    ArrayField,
    FileField,
    ReferenceField,
    useShowController,
} from 'react-admin';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BoxedShowLayout, RaBox } from 'ra-compact-ui';

const useStyles = makeStyles({
    root: {
        paddingTop: '0px',
        paddingBottom: '0px'
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
});

export const OrderListShow = (props) => {
    const { record } = useShowController(props);
    const classes = useStyles();

    return (
        <Show title="" actions={false} {...props}>
            <BoxedShowLayout className={classes.root}>
                <RaBox display="flex" flexWrap="wrap" flexGrow={12} className={classes.root}>
                    <ArrayField label="Order Details" source="OrderDetails">
                        <Datagrid>
                            <TextField label="Release #" source="Release_Num"/>
                            <TextField label="Quantity" source="Quantity"/>
                            <ReferenceField label="Precision" source="PrecisionID" reference="precisions" sortable={false} link={false}>
                                <TextField source="Precision" />
                            </ReferenceField>
                            <ReferenceField label="Product" source="ProductID" reference="products" sortable={false} link={false}>
                                <TextField source="Product" />
                            </ReferenceField>
                            <ReferenceField label="Pickup Location" source="PickupLocationID" reference="pickuplocations" sortable={false} link={false}>
                                <TextField source="Pickup_Location" />
                            </ReferenceField>
                            <ReferenceField label="Delivery Location" source="DeliveryLocationID" reference="deliverylocations" sortable={false} link={false}>
                                <TextField source="Delivery_Location" />
                            </ReferenceField>
                        </Datagrid>
                    </ArrayField>
                </RaBox>
            </BoxedShowLayout>
        </Show>
    );
};

export default OrderListShow;
