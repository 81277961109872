import { useGetOne, TextInput, Loading, Error, required } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    input: {
        marginTop: '0px',
        marginBottom: '0px',
        padding: '0px 4px 0px 2px;',
    },
});

const ReleaseNum = (props) => {
    const classes = useStyles();
    const { data, loading, error } = useGetOne('releasenums', 0);
    if (loading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return <TextInput initialValue={data.Release_Num} {...props} />;
};

export default ReleaseNum;
