import React from 'react';
import { Layout, MenuItemLink, Responsive } from 'react-admin';
import CustomAppBar from './CustomAppBar';
import BookIcon from '@material-ui/icons/Book';
import SettingsIcon from '@material-ui/icons/Settings';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import LabelIcon from '@material-ui/icons/Label';
import AssessmentIcon from '@material-ui/icons/Assessment';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PinDropIcon from '@material-ui/icons/PinDrop';
import InboxIcon from '@material-ui/icons/Inbox';
import PeopleIcon from '@material-ui/icons/People';
import ReceiptIcon from '@material-ui/icons/Receipt';
import LandscapeIcon from '@material-ui/icons/Landscape';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const menuItems = [
    { name: 'orders', text: 'Orders', icon: <InboxIcon /> },
    { name: 'customers', text: 'Customers', icon: <PeopleIcon /> },
    { name: 'products', text: 'Products', icon: <LandscapeIcon /> },
    { name: 'pickuplocations', text: 'Pickup Locations', icon: <PinDropIcon /> },
    { name: 'deliverylocations', text: 'Delivery Locations', icon: <PinDropIcon /> },
    { name: 'truckingcompanies', text: 'Trucking Companies', icon: <LocalShippingIcon /> },
    { name: 'orderdetails', text: 'Order Reconciliation', icon: <AssessmentIcon /> },
];

const MyMenu = ({ onMenuClick, logout }) => (
  <div style={{paddingTop: '20px'}}>
    { menuItems.map(item => (
      <MenuItemLink
        key={item.name}
        to={`/${item.name}`}
        primaryText={item.text}
        leftIcon={item.icon}
        onClick={onMenuClick}
      />
    ))}
    <MenuItemLink
        key="reports"
        to="/reports/create"
        primaryText="Billing Report"
        leftIcon={<ReceiptIcon />}
        onClick={onMenuClick}
    />
    <MenuItemLink
        key="ordersexport"
        to="/ordersexport"
        primaryText="Orders Export"
        leftIcon={<CloudDownloadIcon />}
        onClick={onMenuClick}
    />
    <Responsive
      small={logout}
      medium={null}
    />
  </div>
);

const MyLayout = props => <Layout {...props} menu={MyMenu} appBar={CustomAppBar} />;

export default MyLayout;
