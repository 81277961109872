import React from 'react';
import { Pagination, Filter, List, Edit, Create, SimpleForm, Datagrid, TextField, EmailField, NumberField, BooleanField, TextInput, DateInput, NumberInput, BooleanInput, ReferenceInput, ReferenceField, SelectInput, required } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    expandIcon: {
        padding: '0px'
    },
    expandIconCell: {
        padding: '0px'
    },
    expandedPanel: {
        padding: '0px'
    },
    headerRow: {
        borderLeftColor: 'white',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
        fontSize: '0.75rem !important'
    },
    headerCell: {
        padding: '1px 4px 1px 2px;',
        whiteSpace: 'nowrap',
        fontWeight: 'bold',
        fontSize: '0.75rem !important'
    },
    rowCell: {
        padding: '1px 4px 1px 2px;',
        whiteSpace: 'nowrap',
        fontSize: '0.75rem !important'
    },
    row: {
        fontSize: '0.75rem !important'
    },
    tbody: {
        fontSize: '0.75rem !important'
    }
}));

const TruckingCompanyFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

const TruckingCompanyPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

export const TruckingCompanyList = props => {
    const classes = useStyles(props);

    return (
        <List
            title="Trucking Companies"
            sort={{ field: 'Trucking_Company', order: 'ASC' }}
            perPage={100}
            pagination={<TruckingCompanyPagination />}
            filters={<TruckingCompanyFilter />}
            bulkActionButtons={false}
            {...props}
        >
            <Datagrid
                rowClick="edit"
                classes={{
                    tbody: classes.tbody,
                    headerRow: classes.headerRow,
                    headerCell: classes.headerCell,
                    row: classes.row,
                    rowCell: classes.rowCell
                }}
            >
                <TextField label="Company" source="Trucking_Company" />
                <TextField label="Email" source="Carrier_Email" />
                <TextField label="Abbreviation" source="Carrier_Abbreviation" />
                <TextField label="Billing Street" source="Carrier_Billing_Street_Address" />
                <TextField label="Billing City" source="Carrier_Billing_City" />
                <TextField label="Billing Phone" source="Carrier_Contact_Phone" />
            </Datagrid>
        </List>
    )
}

export const TruckingCompanyEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput label="Company" source="Trucking_Company" />
            <TextInput label="Email" source="Carrier_Email" />
            <TextInput label="Abbreviation" source="Carrier_Abbreviation" />
            <BooleanInput label="Email Trucking Manifest" source="Email_TM_To" />
            <TextInput label="Legal Entity" source="Carrier_Legal_Entity" />
            <TextInput label="Entity Type" source="Carrier_Entity_Type" />
            <TextInput label="SoS Entity" source="Carrier_SoS_Entity_" />
            <TextInput label="Billing Street" source="Carrier_Billing_Street_Address" />
            <TextInput label="Billing City" source="Carrier_Billing_City" />
            <TextInput label="Billing State" source="Carrier_Billing_State" />
            <TextInput label="Billing Zip" source="Carrier_Billing_ZIP" />
            <TextInput label="Contact First Name" source="Carrier_Contact_First_Name" />
            <TextInput label="Contact NickName" source="Carrier_Contact_Nickname" />
            <TextInput label="Contact Last Name" source="Carrier_Contact_Last_Name" />
            <TextInput label="Contact Phone" source="Carrier_Contact_Phone" />
            <TextInput label="Contact Fax" source="Carrier_Contact_Fax" />
            <TextInput label="Email2" source="Carrier_Email2" />
        </SimpleForm>
    </Edit>
);

export const TruckingCompanyCreate = props => (
    <Create
        title="Create Trucking Company"
        redirect="list"
        {...props}
    >
        <SimpleForm>
            <TextInput label="Company" source="Trucking_Company" />
            <TextInput label="Email" source="Carrier_Email" />
            <TextInput label="Abbreviation" source="Carrier_Abbreviation" />
            <BooleanInput label="Email Trucking Manifest" source="Email_TM_To" />
            <TextInput label="Legal Entity" source="Carrier_Legal_Entity" />
            <TextInput label="Entity Type" source="Carrier_Entity_Type" />
            <TextInput label="SoS Entity" source="Carrier_SoS_Entity_" />
            <TextInput label="Billing Street" source="Carrier_Billing_Street_Address" />
            <TextInput label="Billing City" source="Carrier_Billing_City" />
            <TextInput label="Billing State" source="Carrier_Billing_State" />
            <TextInput label="Billing Zip" source="Carrier_Billing_ZIP" />
            <TextInput label="Contact First Name" source="Carrier_Contact_First_Name" />
            <TextInput label="Contact NickName" source="Carrier_Contact_Nickname" />
            <TextInput label="Contact Last Name" source="Carrier_Contact_Last_Name" />
            <TextInput label="Contact Phone" source="Carrier_Contact_Phone" />
            <TextInput label="Contact Fax" source="Carrier_Contact_Fax" />
            <TextInput label="Email2" source="Carrier_Email2" />
        </SimpleForm>
    </Create>
);
