import * as React from 'react';
import { Fragment, useCallback, FC } from 'react';
import classnames from 'classnames';
import { UrlField,
    List,
    Edit,
    Create,
    SimpleForm,
    Datagrid,
    TextField,
    EmailField,
    NumberField,
    BooleanField,
    DateField,
    TextInput,
    DateInput,
    NumberInput,
    BooleanInput,
    ReferenceInput,
    ReferenceField,
    SelectInput,
    CardActions,
    CreateButton,
    EditButton,
    SaveButton,
    Pagination,
    TopToolbar,
    sanitizeListRestProps,
    required } from 'react-admin';
import { Route, RouteChildrenProps, useHistory } from 'react-router-dom';
import { Drawer, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import OrderDetailEdit from './OrderDetailEdit';
import OrderDetailListFilterForm from './OrderDetailListFilterForm';
import { ColorField, ColorInput } from 'react-admin-color-input';
import { Box, Toolbar } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        fontSize: '0.75rem !important'
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 400,
    },
    drawerPaper: {
        zIndex: 100,
    },
    headerRow: {
        borderLeftColor: 'white',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
        fontSize: '0.75rem !important'
    },
    headerCell: {
        padding: '1px 4px 1px 2px;',
        whiteSpace: 'nowrap',
        fontWeight: 'bold',
        fontSize: '0.75rem !important'
    },
    rowCell: {
        padding: '1px 4px 1px 2px;',
        whiteSpace: 'nowrap',
        fontSize: '0.75rem !important',
        overflow: 'hidden',
        textOverflow:'ellipsis',
        maxWidth: '180px'
    },
    row: {
        fontSize: '0.75rem !important'
    },
    tbody: {
        fontSize: '0.75rem !important'
    },
    rowEven: {
        backgroundColor: '#eee',
    },
    rowOdd: {
        backgroundColor: '#fff',
    },
    topToolbar: {
        paddingTop: '0px',
        display: 'block'
    }
}));

const OrderDetailListPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const OrderDetailListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    setFilters,
    ...rest
  } = props;
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <Box display="flex" justifyContent="flex-end" width="100%">
        <OrderDetailListFilterForm />
      </Box>
      <Box display="flex" justifyContent="flex-end" width="100%">
        <OrderDetailListPagination />
      </Box>
    </TopToolbar>
  );
};

const OrderDetailList: FC<ListProps> = props => {
    const classes = useStyles();
    const history = useHistory();

    const handleClose = useCallback(() => {
        history.push('/orderdetails');
    }, [history]);

    return (
        <div className={classes.root}>
            <Route path="/orderdetails/:id">
                {({ match }: RouteChildrenProps<{ id: string }>) => {
                    const isMatch = !!(
                        match &&
                        match.params &&
                        match.params.id !== 'create'
                    );

                    return (
                        <Fragment>
                            <List
                                title="Order Reconciliation"
                                sort={{ field: 'Order_Num', order: 'DESC' }}
                                perPage={100}
                                pagination={<OrderDetailListPagination />}
                                className={classnames(classes.list, {
                                    [classes.listWithDrawer]: isMatch,
                                })}
                                bulkActionButtons={false}
                                actions={<OrderDetailListActions className={classes.topToolbar}/>}
                                {...props}
                            >
                                <Datagrid
                                    rowClick="edit"
                                    classes={{
                                        tbody: classes.tbody,
                                        headerRow: classes.headerRow,
                                        headerCell: classes.headerCell,
                                        row: classes.row,
                                        rowCell: classes.rowCell,
                                        rowOdd: classes.rowOdd,
                                        rowEven: classes.rowEven
                                    }}
                                    optimized
                                >
                                    <ColorField label="" source="Mill_Color" showText={false} />
                                    <TextField label="Order #" source="Order_Num" />
                                    <ReferenceField label="Product" source="ProductID" reference="products" link={false}>
                                        <TextField source="Product" />
                                    </ReferenceField>
                                    <ReferenceField label="Pickup Location" source="PickupLocationID" reference="pickuplocations" link={false}>
                                        <TextField source="Pickup_Location" />
                                    </ReferenceField>
                                    <ReferenceField label="Customer" source="CustomerID" reference="customers" sortable={false} link={false}>
                                        <TextField source="Customer" />
                                    </ReferenceField>
                                    <NumberField label="QTY Ordered" source="Quantity" />
                                    <NumberField label="QTY Loaded" source="Qty_Loaded" />
                                    <BooleanField source="Confirmed" TrueIcon={CheckBoxIcon} FalseIcon={CheckBoxOutlineBlankIcon} />
                                    <DateField source="Delivery_Date" />
                                    <ReferenceField label="Trucking Company" source="CarrierID" reference="truckingcompanies" link={false}>
                                        <TextField source="Trucking_Company" />
                                    </ReferenceField>
                                    <EditButton />
                                </Datagrid>
                            </List>
                            <Drawer
                                variant="persistent"
                                open={isMatch}
                                anchor="right"
                                onClose={handleClose}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                            >
                                {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                                {isMatch ? (
                                    <OrderDetailEdit
                                        id={match.params.id}
                                        onCancel={handleClose}
                                        {...props}
                                    />
                                ) : null}
                            </Drawer>
                        </Fragment>
                    );
                }}
            </Route>
        </div>
    );
};

export default OrderDetailList;
