import OrderCreate from './OrderCreate';
import OrderEdit from './OrderEdit';
import OrderList from './OrderList';
import OrderShow from './OrderShow';

export default {
    list: OrderList,
    edit: OrderEdit,
    create: OrderCreate,
    show: OrderShow,
};
