import { useGetOne, TextInput, Loading, Error } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    input: {
        marginTop: '0px',
        marginBottom: '0px',
        padding: '0px 4px 0px 2px;',
    },
});

const OrderNum = ({ record }) => {
    const classes = useStyles();
    const { data, loading, error } = useGetOne('ordernums', 0);
    if (loading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return <TextInput disabled className={classes.input} initialValue={data.Order_Num} source="Order_Num" label="Order Num" />;
};

export default OrderNum;
