import React from 'react';
import { Filter,
         Pagination,
         List,
         Edit,
         Create,
         SimpleForm,
         Datagrid,
         TextField,
         EmailField,
         NumberField,
         BooleanField,
         DateField,
         TextInput,
         DateInput,
         NumberInput,
         BooleanInput,
         ReferenceInput,
         ReferenceField,
         AutocompleteInput,
         required } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    expandIcon: {
        padding: '0px'
    },
    expandIconCell: {
        padding: '0px'
    },
    expandedPanel: {
        padding: '0px'
    },
    headerRow: {
        borderLeftColor: 'white',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
        fontSize: '0.75rem !important'
    },
    headerCell: {
        padding: '1px 4px 1px 2px;',
        whiteSpace: 'nowrap',
        fontWeight: 'bold',
        fontSize: '0.75rem !important'
    },
    rowCell: {
        padding: '1px 4px 1px 2px;',
        whiteSpace: 'nowrap',
        fontSize: '0.75rem !important'
    },
    row: {
        fontSize: '0.75rem !important'
    },
    tbody: {
        fontSize: '0.75rem !important'
    }
}));

const DeliveryLocationFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

const DeliveryLocationPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

export const DeliveryLocationList = props => {
    const classes = useStyles(props);

    return (
        <List
            title="Delivery Locations"
            sort={{ field: 'Delivery_Location', order: 'ASC' }}
            perPage={100}
            pagination={<DeliveryLocationPagination />}
            filters={<DeliveryLocationFilter />}
            bulkActionButtons={false}
            {...props}
        >
            <Datagrid
                rowClick="edit"
                classes={{
                    tbody: classes.tbody,
                    headerRow: classes.headerRow,
                    headerCell: classes.headerCell,
                    row: classes.row,
                    rowCell: classes.rowCell
                }}
            >
                <ReferenceField label="Customer" source="CustomerID" reference="customers">
                    <TextField source="Customer" />
                </ReferenceField>
                <TextField label="Delivery Location" source="Delivery_Location" />
                <TextField label="Street Address" source="Street_Address" />
                <TextField label="City" source="City" />
                <TextField label="State" source="State" />
                <TextField label="Zip" source="Zip" />
                <TextField label="Contacts" source="Contacts" />
                <TextField label="Phone" source="Phone" />
                <TextField label="Contact2" source="Contact2" />
                <TextField label="Phone2" source="Phone2" />
                <TextField label="Delivery Hours" source="Delivery_Hours" />
            </Datagrid>
        </List>
    )
}

export const DeliveryLocationEdit = props => (
    <Edit undoable={false} {...props}>
        <SimpleForm>
            <ReferenceInput
                label="Customer"
                source="CustomerID"
                sort={{ field: 'Customer', order: 'ASC' }}
                reference="customers" validate={[required()]}>
                <AutocompleteInput optionText="Customer" />
            </ReferenceInput>
            <TextInput disabled source="id" />
            <TextInput source="Delivery_Location" />
            <TextInput source="Street_Address" />
            <TextInput source="City" />
            <TextInput source="State" />
            <TextInput source="Zip" />
            <TextInput source="Contacts" />
            <TextInput source="Phone" />
            <TextInput source="Contact2" />
            <TextInput source="Phone2" />
            <TextInput source="Delivery_Hours" />
        </SimpleForm>
    </Edit>
);

export const DeliveryLocationCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <ReferenceInput
                label="Customer"
                source="CustomerID"
                reference="customers"
                sort={{ field: 'Customer', order: 'ASC' }}
                validate={[required()]}>
                <AutocompleteInput optionText="Customer" />
            </ReferenceInput>
            <TextInput source="Delivery_Location" />
            <TextInput source="Street_Address" />
            <TextInput source="City" />
            <TextInput source="State" />
            <TextInput source="Zip" />
            <TextInput source="Contacts" />
            <TextInput source="Phone" />
            <TextInput source="Contact2" />
            <TextInput source="Phone2" />
            <TextInput source="Delivery_Hours" />
        </SimpleForm>
    </Create>
);
