import React, { Component } from "react";
import { fetchUtils, Admin, Login, Resource, ListGuesser, EditGuesser } from "react-admin";
import jsonServerProvider from "ra-data-json-server";
import MyLayout from './MyLayout';
import orders from './orders';
import orderdetails from './orderdetails';
// TODO: Fix remaining objects
import { OrderListExport } from './orders/OrderListExport';
import { CustomerList, CustomerEdit, CustomerCreate } from './Customers';
import { ProductList, ProductEdit, ProductCreate } from './Products';
import { DeliveryLocationList, DeliveryLocationEdit, DeliveryLocationCreate } from './DeliveryLocations';
import { PickupLocationList, PickupLocationEdit, PickupLocationCreate } from './PickupLocations';
import { TruckingCompanyList, TruckingCompanyEdit, TruckingCompanyCreate } from './TruckingCompanies';
import { TruckList, TruckEdit, TruckCreate } from './Trucks';
import { ReportCreate } from './Reports';
import Dashboard from './Dashboard';
import authProvider from './authProvider';
import { createTheme } from '@material-ui/core/styles';
import { defaultTheme } from 'react-admin';
import merge from 'lodash/merge';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';

const MyLoginPage = () => (
    <Login
        // A random image that changes everyday
        backgroundImage="https://source.unsplash.com/user/davidmarcu/1600x900"
    />
);

export const theme = createTheme(
    merge({}, defaultTheme, {
        palette: {
            secondary: {
                main: "#52ad7a", // Not far from orange
            },
        }
    })
);

const fetchJson = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    options.headers.set('x-access-token', localStorage.getItem('token'));
    return fetchUtils.fetchJson(url, options);
}

const dataProvider = jsonServerProvider("https://app.plgs.io/api", fetchJson);

class App extends Component {
  render() {
    return (
      <Admin layout={MyLayout} theme={theme} loginPage={MyLoginPage} authProvider={authProvider} dataProvider={dataProvider}>
        <Resource name="orders" options={{ label: 'Orders' }} {...orders} />
        <Resource name="customers" options={{ label: 'Customers' }} list={CustomerList} edit={CustomerEdit} create={CustomerCreate} />
        <Resource name="products" options={{ label: 'Products' }} list={ProductList} edit={ProductEdit} create={ProductCreate} />
        <Resource name="pickuplocations" options={{ label: 'Pickup Locations' }} list={PickupLocationList} edit={PickupLocationEdit} create={PickupLocationCreate} />
        <Resource name="deliverylocations" options={{ label: 'Delivery Locations' }} list={DeliveryLocationList} edit={DeliveryLocationEdit} create={DeliveryLocationCreate} />
        <Resource name="truckingcompanies" options={{ label: 'Trucking Companies' }} list={TruckingCompanyList} edit={TruckingCompanyEdit} create={TruckingCompanyCreate} />
        <Resource name="orderdetails" options={{ label: 'Order Reconciliation' }} {...orderdetails} />
        <Resource name="ordersexport" options={{ label: 'Orders Export' }} list={OrderListExport} />

        <Resource name="trucks" options={{ label: 'Trucks' }} edit={TruckEdit} create={TruckCreate} />
        <Resource name="precisions" options={{ label: 'Precisions' }} />
        <Resource name="units" options={{ label: 'Units' }} />
        <Resource name="reports" options={{ label: 'Reports' }} create={ReportCreate} />
        <Resource name="ordernums"/>
        <Resource name="releasenums"/>
      </Admin>
    );
  }
}

export default App;
