import React, { useState, useCallback } from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    DateInput,
    NumberInput,
    BooleanInput,
    ReferenceInput,
    SelectInput,
    ArrayInput,
    SimpleFormIterator,
    AutocompleteInput,
    FormWithRedirect,
    SaveButton,
    DeleteButton,
    TopToolbar,
    required,
} from 'react-admin';
import { Box, Toolbar, IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import {useForm} from 'react-final-form';

const buttonIcon = <LibraryBooksIcon />;

const GenerateReportButton = ({
    icon = buttonIcon,
    label = 'ra.action.create',
    ...props
    }) => {

    const form = useForm();

    const generateReport = useCallback(() => {
        var formdata = form.getState().values;
        console.log('generateReport formdata:', formdata);
        fetch( `/api/reports`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(formdata) })
            .then(response => response.blob())
            .then(blob => {
                console.log('blob:', blob);
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = formdata.Start_Date + "-to-" + formdata.End_Date + "-billing-report.pdf";
                document.body.appendChild(a);
                a.click();
                a.remove();
            })
            .catch((e) => {
                console.log('exception:',e);
            });
    }, [form]);

    return (
        <Button
            onClick={generateReport}
            {...props}
        >
        {icon} &nbsp;Generate Report
        </Button>
    );
};

const ReportCreateForm = props => (
    <FormWithRedirect
        {...props}
        render={formProps => (
            <form>
                <Box p="1em">
                    <DateInput source="Start_Date" />
                </Box>
                <Box p="1em">
                    <DateInput source="End_Date" />
                </Box>
                <Toolbar>
                    <Box display="flex" justifyContent="space-between" width="100%">
                        <GenerateReportButton
                            color="primary"
                        />
                    </Box>
                </Toolbar>
            </form>
        )}
    />
);

export const ReportCreate = props => (
    <Create {...props}>
        <ReportCreateForm />
    </Create>
);
