import * as React from 'react';
import { FC } from 'react';
import {
    useEditController,
    useTranslate,
    DateInput,
    TextInput,
    NumberInput,
    BooleanInput,
    SimpleForm,
    DateField,
    EditProps,
    Identifier,
    Toolbar,
    Button,
    SaveButton,
    Record,
    required,
    translate,
    useGetOne,
} from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';
import { IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import OrderDetailEditToolbar from './OrderDetailEditToolbar';

/*const OrderDetailEditToolbar = ({ onCancel, ...props }) => (
    <Toolbar {...props} >
        <SaveButton />
        <Button label="ra.action.cancel" onClick={onCancel}>
            <IconCancel />
        </Button>
    </Toolbar>
);*/

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 40,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1em',
    },
    form: {
        [theme.breakpoints.up('xs')]: {
            width: 400,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            marginTop: -30,
        },
    },
    inlineField: {
        display: 'inline-block',
        width: '50%',
    },
}));

interface Props extends EditProps {
    onCancel: () => void;
}

interface OrderDetail extends Record {
    Qty_Loaded: number;
    Confirmed: boolean;
    OrderID: Identifier;
    Release_Num: string;
}

const OrderDetailEdit: FC<Props> = ({ onCancel, ...props }) => {
    console.log('props:', props);
    const classes = useStyles();
    const controllerProps = useEditController(props);
    console.log('controller props:', controllerProps);
    const { data, loading, error } = useGetOne('orderdetails', props.id);
    if (error) {
        console.log('error:', error);
        return null;
    }
    console.log('data:', data);
    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <IconButton onClick={onCancel}>
                    <CloseIcon />
                </IconButton>
            </div>
            <SimpleForm
                className={classes.form}
                basePath={props.basePath}
                basePath={controllerProps.basePath}
                record={controllerProps.record}
                save={controllerProps.save}
                version={controllerProps.version}
                redirect="list"
                resource="orderdetails"
                toolbar={<OrderDetailEditToolbar onCancel={onCancel}/>}
            >
                <DateInput label="Delivery Date" source="Delivery_Date" />
                <NumberInput label="Qty Loaded" source="Qty_Loaded" />
                <BooleanInput source="Confirmed" />
            </SimpleForm>
        </div>
    );
};

export default OrderDetailEdit;
