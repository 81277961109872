import React from 'react';
import { Pagination, Filter, List, Edit, Create, SimpleForm, Datagrid, TextField, EmailField, NumberField, BooleanField, DateField, TextInput, DateInput, NumberInput, BooleanInput, ReferenceInput, ReferenceField, SelectInput, required } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { ColorField, ColorInput } from 'react-admin-color-input';

const useStyles = makeStyles(theme => ({
    expandIcon: {
        padding: '0px'
    },
    expandIconCell: {
        padding: '0px'
    },
    expandedPanel: {
        padding: '0px'
    },
    headerRow: {
        borderLeftColor: 'white',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
        fontSize: '0.75rem !important'
    },
    headerCell: {
        padding: '1px 4px 1px 2px;',
        whiteSpace: 'nowrap',
        fontWeight: 'bold',
        fontSize: '0.75rem !important'
    },
    rowCell: {
        padding: '1px 4px 1px 2px;',
        whiteSpace: 'nowrap',
        fontSize: '0.75rem !important'
    },
    row: {
        fontSize: '0.75rem !important'
    },
    tbody: {
        fontSize: '0.75rem !important'
    }
}));

const PickupLocationFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

const PickupLocationPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

export const PickupLocationList = props => {
    const classes = useStyles(props);

    return (
        <List
            title="Pickup Locations"
            filters={<PickupLocationFilter />}
            perPage={100}
            pagination={<PickupLocationPagination />}
            sort={{ field: 'Pickup_Location', order: 'ASC' }}
            bulkActionButtons={false}
            {...props}
        >
            <Datagrid
                rowClick="edit"
                classes={{
                    tbody: classes.tbody,
                    headerRow: classes.headerRow,
                    headerCell: classes.headerCell,
                    row: classes.row,
                    rowCell: classes.rowCell
                }}
            >
                <ColorField label="Color" source="Mill_Color" showText={false} />
                <TextField label="Pickup Location" source="Pickup_Location" />
                <TextField label="Street" source="Mill_Street_Address" />
                <TextField label="City" source="Mill_City" />
                <TextField label="State" source="Mill_State" />
                <TextField label="Zip" source="Mill_Zip" />
                <TextField label="County" source="Mill_County" />
                <TextField label="Contacts" source="Mill_Contacts" />
                <TextField label="Phone" source="Mill_Phone" />
                <TextField label="Pickup Hours" source="Mill_PickUp_Hours" />
                <TextField label="Email" source="Mill_Email" />
            </Datagrid>
        </List>
    )
}

export const PickupLocationEdit = props => (
    <Edit undoable={false} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="Pickup_Location" />
            <TextInput source="Mill_Street_Address" />
            <TextInput source="Mill_City" />
            <TextInput source="Mill_State" />
            <TextInput source="Mill_Zip" />
            <TextInput source="Mill_County" />
            <TextInput source="Mill_Contacts" />
            <TextInput source="Mill_Phone" />
            <TextInput source="Mill_Fax" />
            <TextInput source="Mill_Email" />
            <TextInput source="Mill_PickUp_Hours" />
            <ColorInput source="Mill_Color" />
        </SimpleForm>
    </Edit>
);

export const PickupLocationCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput source="Pickup_Location" />
            <TextInput source="Mill_Street_Address" />
            <TextInput source="Mill_City" />
            <TextInput source="Mill_State" />
            <NumberInput source="Mill_Zip" />
            <TextInput source="Mill_County" />
            <TextInput source="Mill_Contacts" />
            <TextInput source="Mill_Phone" />
            <TextInput source="Mill_Fax" />
            <TextInput source="Mill_Email" />
            <TextInput source="Mill_PickUp_Hours" />
            <ColorInput source="Mill_Color" />
        </SimpleForm>
    </Create>
);
