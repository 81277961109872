import React from 'react';
import {
    Datagrid,
    TextField,
    DateField,
    EditButton,
    Show,
    SimpleShowLayout,
    ArrayField,
    FileField,
    ReferenceField,
    TopToolbar,
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import { BoxedShowLayout, RaBox } from 'ra-compact-ui';
import OrderCloneButton from './OrderCloneButton';

const useStyles = makeStyles({
    root: {
        paddingTop: '0px',
        paddingBottom: '0px'
    },
});

const MyEmailField = ({ record, source, text }) =>
    record && record[source] != null
        ? <a target="_blank" href={`mailto:${record[source]}`}>{text}</a>
        : null;

const OrderShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <OrderCloneButton
            record={data}
            basePath={basePath}
            mr={5}
        />
        <EditButton basePath={basePath} record={data} />
    </TopToolbar>
);

export const OrderShow = (props) => {
    const classes = useStyles();

    return (
        <Show actions={<OrderShowActions />} {...props}>
            <BoxedShowLayout {...props}>
                <RaBox display="flex" >
                    <RaBox display="flex" flexWrap="wrap" flexGrow={3}>
                        <RaBox display="inline-flex" flexDirection="column" flexGrow={1}>
                            <TextField label="Order Number" source="Order_Num" />
                            <TextField label="PO#" source="PO_Num" />
                            <DateField label="Order Date" source="Order_Date" locales="en-US" options={{ timeZone: 'UTC' }} />
                        </RaBox>
                    </RaBox>
                    <RaBox display="flex" flexWrap="wrap" flexGrow={3}>
                        <RaBox display="inline-flex" flexDirection="column" flexGrow={1}>
                            <ReferenceField label="Trucking Company" source="CarrierID" reference="truckingcompanies" sortable={false}>
                                <TextField source="Trucking_Company" />
                            </ReferenceField>
                            <ReferenceField label="Customer" source="CustomerID" reference="customers" sortable={false}>
                                <TextField source="Customer" />
                            </ReferenceField>
                        </RaBox>
                    </RaBox>
                    <RaBox display="flex" flexWrap="wrap" flexGrow={3}>
                        <RaBox display="inline-flex" flexDirection="column" flexGrow={1}>
                            <DateField label="Pick-Up Date" source="PickUp_Date" sortable={false} locales="en-US" options={{ timeZone: 'UTC' }} />
                            <DateField label="Delivery Date" source="Delivery_Date" sortable={false} locales="en-US" options={{ timeZone: 'UTC' }} />
                        </RaBox>
                    </RaBox>
                    <RaBox display="flex" flexWrap="wrap" flexGrow={3}>
                        <RaBox display="inline-flex" flexDirection="column" flexGrow={1}>
                            <TextField label="Order Type" source="Order_Type" />
                        </RaBox>
                    </RaBox>
                </RaBox>
                <RaBox display="flex" >
                    <RaBox display="flex" flexWrap="wrap" flexGrow={1}>
                        <ArrayField label="Preview Docs" source="OrderDetails">
                            <Datagrid>
                                <TextField label="Release #" source="Release_Num"/>
                                <ReferenceField label="Pickup Location" source="PickupLocationID" reference="pickuplocations" sortable={false} link={true}>
                                    <TextField source="Pickup_Location" />
                                </ReferenceField>
                                <ReferenceField label="Delivery Location" source="DeliveryLocationID" reference="deliverylocations" sortable={false} link={true}>
                                    <TextField source="Delivery_Location" />
                                </ReferenceField>
                                <FileField label="Bill-of-Lading" source="Bill_Of_Lading_URL" target="_blank" title="View Bill Of Lading PDF"/>
                                <FileField label="Trucking Manifests" source="Trucking_Manifest_URL" target="_blank" title="View Trucking Manifest PDF" />
                                <FileField label="Packing Slips" source="Packing_Slip_URL" target="_blank" title="View Packing Slip PDF" />
                                <FileField label="SKU Labels" source="SKU_URL" target="_blank" title="View SKU PDF" />
                            </Datagrid>
                        </ArrayField>
                    </RaBox>
                </RaBox>
                <RaBox display="flex" >
                    <RaBox display="flex" flexWrap="wrap" flexGrow={1}>
                        <ArrayField label="Email Links" source="OrderDetails">
                            <Datagrid>
                                <TextField label="Release #" source="Release_Num"/>
                                <MyEmailField label="Order Docs" source="PUN_Email" text="Email Order Docs" />
                                <MyEmailField label="Trucking Manifests" source="TM_Email" text="Email Trucking Manifests (TMs)" />
                            </Datagrid>
                        </ArrayField>
                    </RaBox>
                </RaBox>
            </BoxedShowLayout>
        </Show>
    )
}

export default OrderShow;
