import React from 'react';
import {
    CloneButton} from 'react-admin';
import Queue from '@material-ui/icons/Queue';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';

const defaultIcon = <Queue />;

const omitFieldsFunc = function({ id, Order_Date, Order_Num, PickUp_Date, Delivery_Date, PO_Num, Billing_Notes, Qty_Loaded, Confirmed, ...rest }) {
    let clone = Object.assign({}, rest);
    //console.log('clone before:', clone);
    if ('OrderDetails' in clone) {
        clone['OrderDetails'] = clone['OrderDetails'].map(({id, OrderID, Release_Num, Qty_Loaded, Confirmed, ...rest2}) => rest2);
    }
    //console.log('clone after:', clone);
    return clone;
};

const OrderCloneButton = ({
    record,
    icon = defaultIcon,
    label = 'ra.action.clone',
    ...rest
    }) => (
    <CloneButton
        component={Link}
        to={
            record
                ? {
                      pathname: `/orders/create`,
                      search: stringify({
                          source: JSON.stringify(omitFieldsFunc(record)),
                      }),
                  }
                : `/orders/create`
        }
        label={label}
        {...rest}
    >
        {icon} Clone
    </CloneButton>
);

export default OrderCloneButton;
