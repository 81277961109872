import React from 'react';
import { Pagination, List, Edit, Create, Filter, SimpleForm, Datagrid, TextField, EmailField, NumberField, BooleanField, DateField, TextInput, DateInput, NumberInput, BooleanInput, ReferenceInput, ReferenceField, SelectInput, required } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    expandIcon: {
        padding: '0px'
    },
    expandIconCell: {
        padding: '0px'
    },
    expandedPanel: {
        padding: '0px'
    },
    headerRow: {
        borderLeftColor: 'white',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
        fontSize: '0.75rem !important'
    },
    headerCell: {
        padding: '1px 4px 1px 2px;',
        whiteSpace: 'nowrap',
        fontWeight: 'bold',
        fontSize: '0.75rem !important'
    },
    rowCell: {
        padding: '1px 4px 1px 2px;',
        whiteSpace: 'nowrap',
        fontSize: '0.75rem !important'
    },
    row: {
        fontSize: '0.75rem !important'
    },
    tbody: {
        fontSize: '0.75rem !important'
    }
}));

const ProductFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput label="Product" source="id" reference="products" allowEmpty>
            <SelectInput optionText="Product" />
        </ReferenceInput>
    </Filter>
);

const ProductPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

export const ProductList = props => {
    const classes = useStyles(props);

    return (
        <List
            filters={<ProductFilter />}
            sort={{ field: 'Product', order: 'ASC' }}
            perPage={100}
            pagination={<ProductPagination />}
            bulkActionButtons={false}
            {...props}
        >
            <Datagrid
                rowClick="edit"
                classes={{
                    tbody: classes.tbody,
                    headerRow: classes.headerRow,
                    headerCell: classes.headerCell,
                    row: classes.row,
                    rowCell: classes.rowCell
                }}
            >
                <TextField source="Product" />
                <ReferenceField label="Units" source="UnitID" reference="units" link={false}>
                    <TextField source="Units" />
                </ReferenceField>
                <TextField source="Charge" />
                <TextField source="Classification" />
                <TextField source="Color" />
                <TextField source="Dye" />
                <TextField source="Grind" />
                <TextField source="LifeCycle_Phase" />
                <TextField source="Market_Segment" />
                <TextField source="Size" />
                <TextField source="Species" />
                <BooleanField source="Quantity_In_Stock" />
                <BooleanField source="Warning_Threshold" />
            </Datagrid>
        </List>
    )
}

export const ProductEdit = props => (
    <Edit undoable={false} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="Product" />
            <ReferenceInput
                label="Units"
                source="UnitID"
                reference="units">
                <SelectInput optionText="Units" />
            </ReferenceInput>
            <TextInput source="Charge" />
            <TextInput source="Classification" />
            <TextInput source="Color" />
            <TextInput source="Dye" />
            <TextInput source="Grind" />
            <TextInput source="LifeCycle_Phase" />
            <TextInput source="Market_Segment" />
            <TextInput source="Size" />
            <TextInput source="Species" />
            <BooleanInput source="Quantity_In_Stock" />
            <BooleanInput source="Warning_Threshold" />
            <TextInput source="ConfigurationID" />
            <TextInput source="Description" />
            <TextInput source="A_Quantity" />
        </SimpleForm>
    </Edit>
);

export const ProductCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput source="Product" />
            <ReferenceInput
                label="Units"
                source="UnitID"
                reference="units">
                <SelectInput optionText="Units" />
            </ReferenceInput>
            <TextInput source="Charge" />
            <TextInput source="Classification" />
            <TextInput source="Color" />
            <TextInput source="Dye" />
            <TextInput source="Grind" />
            <TextInput source="LifeCycle_Phase" />
            <TextInput source="Market_Segment" />
            <TextInput source="Size" />
            <TextInput source="Species" />
            <BooleanInput source="Quantity_In_Stock" />
            <BooleanInput source="Warning_Threshold" />
            <TextInput source="ConfigurationID" />
            <TextInput source="Description" />
            <TextInput source="A_Quantity" />
        </SimpleForm>
    </Create>
);
