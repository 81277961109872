import React from 'react';
import { List, Edit, Create, SimpleForm, Datagrid, TextField, EmailField, NumberField, BooleanField, DateField, TextInput, DateInput, NumberInput, BooleanInput, ReferenceInput, ReferenceField, SelectInput, required } from 'react-admin';

export const TruckList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <ReferenceField label="Order" source="OrderID" reference="orders">
                <TextField source="Order_Num" />
            </ReferenceField>
            <ReferenceField label="Trucking Company" source="CarrierID" reference="truckingcompanies">
                <TextField source="Trucking_Company" />
            </ReferenceField>
            <DateField source="Truck_Type" />
            <DateField source="PickUp_Date" />
            <DateField source="Delivery_Date" />
            <BooleanField source="Early_Delivery" />
            <TextField source="Rate_From_Freight_Carrier" />
            <TextField source="Special_Instructions" />
            <BooleanField source="Shipping_Documents" />
            <BooleanField source="Truck_Emailed_Truck_Docs" />
            <BooleanField source="Truck_Emailed_Mill_Docs" />
        </Datagrid>
    </List>
);

export const TruckEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <ReferenceInput label="Order" source="OrderID" reference="orders" validate={[required()]}>
                <SelectInput optionText="Order_Num" />
            </ReferenceInput>
            <ReferenceInput label="Trucking Company" source="CarrierID" reference="truckingcompanies" validate={[required()]}>
                <SelectInput optionText="Trucking_Company" />
            </ReferenceInput>
            <DateInput source="Truck_Type" />
            <DateInput source="PickUp_Date" />
            <DateInput source="Delivery_Date" />
            <BooleanInput source="Early_Delivery" />
            <TextInput source="Rate_From_Freight_Carrier" />
            <TextInput source="Special_Instructions" />
            <BooleanInput source="Shipping_Documents" />
            <BooleanInput source="Truck_Emailed_Truck_Docs" />
            <BooleanInput source="Truck_Emailed_Mill_Docs" />
            <TextInput source="Truck_When_Emailed_Truck_Docs" />
            <TextInput source="Truck_When_Emailed_Mill_Docs" />
            <TextInput source="Source" />
        </SimpleForm>
    </Edit>
);

export const TruckCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput label="Order" source="OrderID" reference="orders" validate={[required()]}>
                <SelectInput optionText="Order_Num" />
            </ReferenceInput>
            <ReferenceInput label="Trucking Company" source="CarrierID" reference="truckingcompanies" validate={[required()]}>
                <SelectInput optionText="Trucking_Company" />
            </ReferenceInput>
            <DateInput source="Truck_Type" />
            <DateInput source="PickUp_Date" />
            <DateInput source="Delivery_Date" />
            <BooleanInput source="Early_Delivery" />
            <TextInput source="Rate_From_Freight_Carrier" />
            <TextInput source="Special_Instructions" />
            <BooleanInput source="Shipping_Documents" />
            <BooleanInput source="Truck_Emailed_Truck_Docs" />
            <BooleanInput source="Truck_Emailed_Mill_Docs" />
            <TextInput source="Truck_When_Emailed_Truck_Docs" />
            <TextInput source="Truck_When_Emailed_Mill_Docs" />
            <TextInput source="Source" />
        </SimpleForm>
    </Create>
);
