import * as React from 'react';
import { Form } from 'react-final-form';
import { Box, Button, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import {
    TextInput,
    NullableBooleanInput,
    CreateButton,
    useListContext
} from 'react-admin';

const OrderDetailListFilterForm = ({ open }) => {
    const {
        displayedFilters,
        filterValues,
        setFilters,
        hideFilter,
    } = useListContext();

    const onSubmit = values => {
        if (Object.keys(values).length > 0) {
            setFilters(values);
        } else {
            setFilters({}, []);
        }
    };

    const resetFilter = () => {
        setFilters({}, []);
    };

    return (
        <div>
        <Form onSubmit={onSubmit} initialValues={filterValues} mb={0} mt={0}>
            {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
                <Box display="flex" justifyContent="space-between" width="100%">
                    <Box display="flex" alignItems="flex-start" mb={0} mt={0}>
                        <Box component="span" mr={2} mb={0} mt={0}>
                            <TextInput
                                resettable
                                helperText={false}
                                source="c"
                                label="Customer"
                            />
                        </Box>
                        <Box component="span" mr={2} mb={0} mt={0}>
                            <TextInput
                                resettable
                                helperText={false}
                                source="p"
                                label="Product"
                            />
                        </Box>
                        <Box component="span" mr={2} mb={0} mt={0}>
                            <TextInput
                                resettable
                                helperText={false}
                                source="r"
                                label="Release #"
                            />
                        </Box>
                    </Box>
                    <Box display="flex" alignItems="flex-end" mb={0} mt={0}>
                        <Box component="span" mr={2} mb={1.5}>
                            <Button variant="outlined" color="primary" type="submit">
                            Filter
                            </Button>
                        </Box>
                        <Box component="span" mr={2} mb={1.5}>
                            <Button variant="outlined" onClick={resetFilter}>
                            Clear
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </form>
            )}
        </Form>
        </div>
    );
};

export default OrderDetailListFilterForm;
