import React from 'react';
import {
    Pagination,
    List,
    Filter,
    Datagrid,
    TextField,
    DateField,
    TextInput,
    BooleanField,
    ReferenceField,
    EditButton,
    Button,
    sanitizeListRestProps,
    TopToolbar,
    downloadCSV,
    ShowButton} from 'react-admin';
import OrderListShow from './OrderListShow';
import OrderListFilterForm from './OrderListFilterForm';
import OrderCloneButton from './OrderCloneButton';
import { makeStyles } from '@material-ui/core/styles';
import { FilterList } from "@material-ui/icons";
import { Box, Toolbar } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

const OrderPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const OrderListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    setFilters,
    ...rest
  } = props;
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <Box display="flex" justifyContent="flex-end" width="100%">
        <OrderListFilterForm />
      </Box>
      <Box display="flex" justifyContent="flex-end" width="100%">
        <OrderPagination />
      </Box>
    </TopToolbar>
  );
};

const useStyles = makeStyles(theme => ({
    expandIcon: {
        padding: '0px'
    },
    expandIconCell: {
        padding: '0px'
    },
    expandedPanel: {
        padding: '0px'
    },
    headerRow: {
        borderLeftColor: 'white',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
        fontSize: '0.75rem !important'
    },
    headerCell: {
        padding: '0px 4px 0px 2px;',
        whiteSpace: 'nowrap',
        fontWeight: 'bold',
        fontSize: '0.75rem !important'
    },
    rowCell: {
        padding: '0px 4px 0px 2px;',
        whiteSpace: 'nowrap',
        fontSize: '0.75rem !important',
        overflow: 'hidden',
        textOverflow:'ellipsis',
        maxWidth: '180px'
    },
    row: {
        fontSize: '0.75rem !important'
    },
    tbody: {
        fontSize: '0.75rem !important'
    },
    rowEven: {
        backgroundColor: '#eee',
    },
    rowOdd: {
        backgroundColor: '#fff',
    },
    topToolbar: {
        paddingTop: '0px',
        display: 'block'
    }
}));

const OrderList = props => {
    const classes = useStyles(props);

    return (
        <List
            sort={{ field: 'Order_Num', order: 'DESC' }}
            perPage={100}
            pagination={<OrderPagination />}
            bulkActionButtons={false}
            actions={<OrderListActions className={classes.topToolbar}/>}
            {...props}
        >
            <Datagrid
                rowClick="expand"
                expand={<OrderListShow />}
                classes={{
                    expandIcon: classes.expandIcon,
                    expandIconCell: classes.expandIconCell,
                    expandedPanel: classes.expandedPanel,
                    tbody: classes.tbody,
                    headerRow: classes.headerRow,
                    headerCell: classes.headerCell,
                    row: classes.row,
                    rowCell: classes.rowCell,
                    rowOdd: classes.rowOdd,
                    rowEven: classes.rowEven
                }}
            >
                <TextField label="Order Number" source="Order_Num" />
                <TextField label="Type" source="Order_Type" sortable={false} />
                <ReferenceField label="Customer" source="CustomerID" reference="customers" sortable={false}>
                    <TextField source="Customer" />
                </ReferenceField>
                <ReferenceField label="Trucking Company" source="CarrierID" reference="truckingcompanies" sortable={false}>
                    <TextField source="Trucking_Company" />
                </ReferenceField>
                <DateField label="Delivery Date" source="Delivery_Date" sortable={false} />
                <BooleanField label="Pending" source="Emailed_Trucking_Manifest" TrueIcon={CheckBoxIcon} FalseIcon={CheckBoxOutlineBlankIcon} />
                <EditButton />
                <ShowButton />
                <OrderCloneButton record={props.record} />
            </Datagrid>
        </List>
    );
}

export default OrderList;
