import React from 'react';
import { Pagination, List, Edit, Create, Filter, Datagrid, TextField, EmailField, NumberField, BooleanField, TextInput, SimpleForm, ReferenceInput, SelectInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    expandIcon: {
        padding: '0px'
    },
    expandIconCell: {
        padding: '0px'
    },
    expandedPanel: {
        padding: '0px'
    },
    headerRow: {
        borderLeftColor: 'white',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
        fontSize: '0.75rem !important'
    },
    headerCell: {
        padding: '1px 4px 1px 2px;',
        whiteSpace: 'nowrap',
        fontWeight: 'bold',
        fontSize: '0.75rem !important'
    },
    rowCell: {
        padding: '1px 4px 1px 2px;',
        whiteSpace: 'nowrap',
        fontSize: '0.75rem !important'
    },
    row: {
        fontSize: '0.75rem !important'
    },
    tbody: {
        fontSize: '0.75rem !important'
    }
}));

const CustomerFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput label="Customer" source="id" reference="customers" allowEmpty>
            <SelectInput optionText="Customer" />
        </ReferenceInput>
    </Filter>
);

const CustomerPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

export const CustomerList = props => {
    const classes = useStyles(props);

    return (
        <List
            filters={<CustomerFilter />}
            sort={{ field: 'Customer', order: 'ASC' }}
            perPage={100}
            pagination={<CustomerPagination />}
            bulkActionButtons={false}
            {...props}
        >
            <Datagrid
                rowClick="edit"
                classes={{
                    tbody: classes.tbody,
                    headerRow: classes.headerRow,
                    headerCell: classes.headerCell,
                    row: classes.row,
                    rowCell: classes.rowCell
                }}
            >
                <TextField label="Customer" source="Customer" />
                <TextField label="Street Address" source="Billing_Street_Address" />
                <TextField label="City" source="Billing_City" />
                <TextField label="State" source="Billing_State" />
                <TextField label="Zip" source="Billing_Zipcode" />
                <TextField label="First Name" source="Contact_First_Name" />
                <TextField label="Last Name" source="Contact_Last_Name" />
                <TextField label="Phone" source="Contact_Phone" />
                <TextField label="Email" source="Contact_Email" />
            </Datagrid>
        </List>
    )
}

export const CustomerEdit = props => (
    <Edit undoable={false} {...props}>
        <SimpleForm redirect="list">
            <TextInput disabled source="id" />
            <TextInput source="Customer" />
            <TextInput source="Category" />
            <TextInput source="Type" />
            <TextInput source="Customer_Type" />
            <TextInput source="Billing_Street_Address" />
            <TextInput source="Billing_City" />
            <TextInput source="Billing_State" />
            <TextInput source="Billing_Zipcode" />
            <TextInput source="Contact_First_Name" />
            <TextInput source="Contact_Last_Name" />
            <TextInput source="Contact_Phone" />
            <TextInput source="Contact_Fax" />
            <TextInput source="Contact_Email" />
        </SimpleForm>
    </Edit>
);

export const CustomerCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput source="Customer" />
            <TextInput source="Category" />
            <TextInput source="Type" />
            <TextInput source="Customer_Type" />
            <TextInput source="Billing_Street_Address" />
            <TextInput source="Billing_City" />
            <TextInput source="Billing_State" />
            <TextInput source="Billing_Zipcode" />
            <TextInput source="Contact_First_Name" />
            <TextInput source="Contact_Last_Name" />
            <TextInput source="Contact_Phone" />
            <TextInput source="Contact_Fax" />
            <TextInput source="Contact_Email" />
        </SimpleForm>
    </Create>
);
