import React from 'react';
import {
    Pagination,
    List,
    Filter,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    TextInput,
    BooleanField,
    ReferenceField,
    EditButton,
    Button,
    sanitizeListRestProps,
    TopToolbar,
    downloadCSV,
    ShowButton} from 'react-admin';
import OrderListShow from './OrderListShow';
import OrderListExportForm from './OrderListExportForm';
import { makeStyles } from '@material-ui/core/styles';
import { FilterList } from "@material-ui/icons";
import { Box, Toolbar } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

const OrderPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const OrderListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    setFilters,
    ...rest
  } = props;
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <Box display="flex" justifyContent="flex-end" width="100%">
        <OrderListExportForm />
      </Box>
      <Box display="flex" justifyContent="flex-end" width="100%">
        <OrderPagination />
      </Box>
    </TopToolbar>
  );
};

const useStyles = makeStyles(theme => ({
    headerRow: {
        borderLeftColor: 'white',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
        fontSize: '0.75rem !important'
    },
    headerCell: {
        padding: '0px 4px 0px 2px;',
        whiteSpace: 'nowrap',
        fontWeight: 'bold',
        fontSize: '0.75rem !important'
    },
    rowCell: {
        padding: '0px 4px 0px 2px;',
        whiteSpace: 'nowrap',
        fontSize: '0.75rem !important',
        overflow: 'hidden',
        textOverflow:'ellipsis',
        maxWidth: '180px'
    },
    row: {
        fontSize: '0.75rem !important'
    },
    tbody: {
        fontSize: '0.75rem !important'
    },
    rowEven: {
        backgroundColor: '#eee',
    },
    rowOdd: {
        backgroundColor: '#fff',
    },
    topToolbar: {
        paddingTop: '0px',
        display: 'block'
    }
}));

export const OrderListExport = props => {
    const classes = useStyles(props);

    return (
        <List
            sort={{ field: 'Order_Num', order: 'DESC' }}
            perPage={100}
            pagination={<OrderPagination />}
            bulkActionButtons={false}
            actions={<OrderListActions className={classes.topToolbar}/>}
            {...props}
        >
            <Datagrid
                classes={{
                    tbody: classes.tbody,
                    headerRow: classes.headerRow,
                    headerCell: classes.headerCell,
                    row: classes.row,
                    rowCell: classes.rowCell,
                    rowOdd: classes.rowOdd,
                    rowEven: classes.rowEven
                }}
            >
                <TextField label="Order Number" source="Order_Num" />
                <TextField source="Customer_Name" />
                <TextField source="Product_Name" />
                <NumberField label="QTY Loaded" source="Qty_Loaded" />
                <TextField source="Product_Units" />
                <TextField source="Pickup_Location" />
                <DateField label="Delivery Date" source="Delivery_Date" sortable={false} />
            </Datagrid>
        </List>
    );
}
                /*<TextField source="Delivery_Location" />
                <TextField source="Delivery_Location_Street_Address" />
                <TextField source="Delivery_Location_City" />
                <TextField source="Delivery_Location_State" />
                <TextField source="Delivery_Location_Zip" />
                <TextField source="Trucking_Company" />
                <TextField label="Truck Type" source="Truck_Type" />
                <TextField label="Truck Rate" source="Truck_Rate" />*/
